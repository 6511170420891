import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const DynamicPage = () => {
  const { l, p, businessId, transactionId } = useParams(); // Extract dynamic parameters

  // Set the default value for 'p' if it's not provided
  const dynamicP = p || "transaction";

  // State to store the fetched data
  const [data, setData] = useState(null);

  // State to handle loading and error states
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Construct the API URL
    let url = `https://prod.gtikit.com/gtikitapplayer/provider/validatebusiness?l=${l}&p=${dynamicP}&id=${businessId}`;
    
    // If transactionId exists, add it to the URL
    if (transactionId) {
      url += `&param=${transactionId}`;
    }

    // Log URL for debugging
    console.log("Request URL:", url);

    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();
        setData(responseData); // Set the data state

      } catch (err) {
        setError(err.message); // Set error if fetching fails
      } finally {
        setLoading(false); // Set loading to false when request is complete
      }
    };

    // Fetch the data when the component is mounted
    fetchData();
  }, [l, dynamicP, businessId, transactionId]); // Added 'transactionId' to dependency array

  // Render the page
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Destructure data from API response
  const {
    Result: {
      BannerUrl,
      Title,
      Description,
      Deals = [],
      BuyNowUrl,
      MetaTitle,
      MetaDescription,
      OgImage,
    } = {},
    FullName,
    ProfilePicture,
  } = data;

  return (
    <div>
      <head>
        <title>{MetaTitle || "Business Validation"}</title>
        <meta name="description" content={MetaDescription || "Details"} />
        <meta property="og:title" content={MetaTitle || Title} />
        <meta property="og:description" content={MetaDescription || Description} />
        <meta property="og:image" content={OgImage || BannerUrl} />
        <meta property="og:url" content={BuyNowUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="theme-color" content="#000000" />
        <link rel="apple-touch-icon" href="/logo192.png" />
        <meta name="apple-itunes-app" content="app-id=6468080564, app-clip-bundle-id=com.MM.GTikitGO, app-clip-display=card" />
        <meta property="og:image" content="https://www.gtikit.com/img/tn.png" />
      </head>

      <main className="max-w-4xl mx-auto px-4 py-8 font-sans">
        <h1 className="text-3xl font-bold mb-6">{Title}</h1>
        <p className="text-lg mb-8">{Description}</p>
        <img src={BannerUrl} alt="Business Banner" className="w-full mb-6 rounded-lg" />

        <section>
          <h2 className="text-2xl font-semibold mb-4">Exclusive Deals</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {Deals.map((deal, index) => (
              <div key={index} className="p-4 border rounded-lg shadow-md">
                <h3 className="font-bold text-lg">{deal.Name}</h3>
                <p>{deal.Description}</p>
              </div>
            ))}
          </div>
        </section>

        <a
          href={BuyNowUrl}
          className="block mt-6 text-blue-600 font-semibold text-xl hover:underline"
        >
          Buy Now
        </a>

        <footer className="mt-10 text-center">
          <div className="flex justify-center items-center">
            <img
              src={ProfilePicture}
              alt="Profile Picture"
              className="rounded-full w-16 h-16 mr-4"
            />
            <p className="text-lg font-medium">{FullName}</p>
          </div>
        </footer>
      </main>
    </div>
  );
};

export default DynamicPage;
