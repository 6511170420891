import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import React Router
import DynamicPage from './components/DynamicPage'; // Import your DynamicPage component

import './App.css'; // Your styling file

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <h1>Welcome to GTikit</h1>
        </header>

        {/* Define Routes */}
        <Routes>
        <Route path="/:l/:p/:businessId/:transactionId?" element={<DynamicPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
